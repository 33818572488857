import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
// import './estilo.css'; // Importe o arquivo de estilos

const Login = ({ setToken }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const response = await axios.post('http://ronnie.dev.br:3003/login', { username, password });
      const token = response.data.token;
      setToken(token);
    } catch (error) {
      console.error('Erro no login:', error);
      showAlert('Erro no Login', 'Usuário e/ou senha incorretos. Tente novamente.');
    }
  };

  function showAlert(title, text) {
    swal({
      title: title,
      text: text,
      icon: 'error',
      button: 'OK',
    });
  }

  return ( 
    <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'center' }}>
      <div className="card medium" style={{ padding: '20px', borderRadius: '5px', backgroundColor: 'transparent'  }}>
        <div className="card-content ">
          <span className="card-title center">Login Policial</span>
          <div className="input-field">
            <input
              type="text"
              id="username"
              className="validate custom-placeholder" // Adicione a classe custom-placeholder aqui
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
          </div>
          <div className="input-field">
            <input
              type="password"
              id="password"
              className="validate custom-placeholder" // Adicione a classe custom-placeholder aqui
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          <div className="center">
            <button
              className="btn waves-effect waves-light green"
              onClick={handleLogin}
            ><i class="material-icons right">send</i>
              Login
            </button>
          </div>
          <div className="center" style={{ marginTop: '20px' }}>
            <p>
              Dúvidas e solicitações de acesso <Link to="/register" className="green-text">Solicitar</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
