import { useEffect, useState,useRef } from 'react'
import axios from 'axios'
import { FaTrashCan } from "react-icons/fa6";
import { FaMagnifyingGlass } from "react-icons/fa6";
import * as XLSX from 'xlsx';
import { FaDownload } from "react-icons/fa6";

const VeiculosApreendidos = () => {

const [formData, setFormData] = useState({
    n_livro: '',
    data_apreensao: '',
    n_procedimento: '',
    ip_boa: '',
    n_autos: '',
    tipo: '',
    marca_modelo: '',
    cor: '',
    placa: '',
    chassi: '',
    n_laudo: '',
    obs: '',
    data_entrega: '',
    imagens: [],
    });

const [registros, setRegistros] = useState([]);
const [registrosFiltrados, setRegistrosFiltrados] = useState([]);

//para trabalhar com o modal
const [showModal, setShowModal] = useState(false);
const [currentRecord, setCurrentRecord] = useState(null);
const modalRef = useRef(null);


//código para trbalhar com o modal
const openModal = (registro) => {
  const modalWidth = 900; // largura da janela modal
  const modalHeight = 450; // altura da janela modal

  // Função para formatar data no formato dd/mm/aaaa
  const formatarDate = (dateString) => {
    if (!dateString) return ''; // Verifica se a string é vazia ou nula
    const dateObject = new Date(dateString);
    if (isNaN(dateObject.getTime())) {
      console.log("Data inválida recebida:", dateString); // Log adicional para identificar entradas inválidas
      return 'Data Inválida';
    }
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return dateObject.toLocaleDateString('pt-BR', options);
  };

  const modalWindow = window.open('', '_blank', `width=${modalWidth},height=${modalHeight}`);
  if (modalWindow) {
    modalWindow.document.write(`
      <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
          }
          .modal {
            padding: 20px;
          }
          .modal-content {
            max-width: 800px;
            margin: 0 auto;
          }
          h2 {
            text-align: center;
          }
          form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          label {
            display: block;
            margin-bottom: 10px;
          }
          input[type="text"],
          input[type="date"],
          textarea {
            width: calc(100% - 10px);
            padding: 8px;
            font-size: 14px;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
          }
          input[type="text"],
          input[type="date"] {
            width: 100%;
          }
          textarea {
            height: 100px;
            resize: vertical;
          }
          .form-group {
            width: calc(33.33% - 10px);
            margin-bottom: 10px;
          }
          .form-group:nth-child(3n + 1) {
            clear: both;
          }
          button {
            margin-top: 10px;
            padding: 10px 20px;
            font-size: 16px;
            cursor: pointer;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
          }
          button:hover {
            background-color: #0056b3;
          }
        </style>
      </head>
      <body>
        <div class="modal">
          <div class="modal-content">
            <h2>Editar Registro</h2>
            <form id="modalForm">
            <!-- Campo oculto para numord SERIAL PRIMARY KEY-->
              <input type="hidden" name="numord" value="${registro.numord || ''}">
              <div class="form-group">
                <label>Nº do Livro:</label>
                <input type="text" name="N_LIVRO" placeholder="Número do Livro" value="${registro.n_livro || ''}">
              </div>
                              <div class="form-group">
                  <label>Data de Apreensão:</label>
                  <input type="text" name="DATA_APREENSAO" placeholder="dd/mm/aaaa" value="${formatarDate(registro.data_apreensao) || ''}">
                </div>
                                <div class="form-group">
                  <label>Nº Procedimento:</label>
                  <input type="text" name="N_Procedimento" placeholder="Número do Procedimento" value="${registro.n_procedimento || ''}">
                </div>
                <div class="form-group">
                  <label>IP-BOA:</label>
                  <input type="text" name="ip_boa" placeholder="IP-BOA" value="${registro.ip_boa || ''}">
                </div>
                <div class="form-group">
                  <label>Nº Autos:</label>
                  <input type="text" name="N_AUTOS" placeholder="Número dos Autos" value="${registro.n_autos || ''}">
                </div>
                <div class="form-group">
                  <label>Tipo:</label>
                  <input type="text" name="TIPO" placeholder="Tipo" value="${registro.tipo || ''}">
                </div>
                <div class="form-group">
                  <label>Marca/Modelo:</label>
                  <input type="text" name="marca_modelo" placeholder="Marca e Modelo" value="${registro.marca_modelo || ''}">
                </div>
                <div class="form-group">
                  <label>Cor:</label>
                  <input type="text" name="COR" placeholder="Cor" value="${registro.cor || ''}">
                </div>
                <div class="form-group">
                  <label>Placa:</label>
                  <input type="text" name="PLACA" placeholder="Placa" value="${registro.placa || ''}">
                </div>
                <div class="form-group">
                  <label>Chassi:</label>
                  <input type="text" name="CHASSI" placeholder="Chassi" value="${registro.chassi || ''}">
                </div>
                <div class="form-group">
                  <label>Nº Laudo:</label>
                  <input type="text" name="N_LAUDO" placeholder="Número do Laudo" value="${registro.n_laudo || ''}">
                </div>
                <div class="form-group">
                  <label>OBS:</label>
                  <textarea name="OBS" placeholder="Observações">${registro.obs || ''}</textarea>
                </div>
                <div class="form-group">
                  <label>Data de Entrega:</label>
                  <input type="text" name="data_entrega" placeholder="dd/mm/aaaa" value="${formatarDate(registro.data_entrega) || ''}">
                </div>
              <!-- Restante do formulário omitido para brevidade -->
              <div>
                <!-- Ajuste os botões para chamar funções de dentro do modal -->
                <button type="button" onclick="handleSaveClick()">Salvar</button>
                <button type="button" onclick="window.close()">Sair</button>
              </div>
            </form>
          </div>
        </div>
      </body>
      </html>
    `);
  } else {
    alert('Não foi possível abrir a nova janela do modal.');
  }

  // A função handleSaveClick é chamada internamente dentro do modal
  modalWindow.handleSaveClick = () => {
    const formData = new FormData(modalWindow.document.getElementById('modalForm'));

    const updatedData = {
      numord: formData.get('numord'),
      N_LIVRO: formData.get('N_LIVRO'),
      DATA_APREENSAO: formData.get('DATA_APREENSAO'),
      N_Procedimento: formData.get('N_Procedimento'),
      ip_boa: formData.get('ip_boa'),
      N_AUTOS: formData.get('N_AUTOS'),
      TIPO: formData.get('TIPO'),
      marca_modelo: formData.get('marca_modelo'),
      COR: formData.get('COR'),
      PLACA: formData.get('PLACA'),
      CHASSI: formData.get('CHASSI'),
      N_LAUDO: formData.get('N_LAUDO'),
      OBS: formData.get('OBS'),
      data_entrega: formData.get('data_entrega'),
    };

    // Verificar se os campos obrigatórios estão preenchidos
    if (!updatedData.N_LIVRO || !updatedData.DATA_APREENSAO || !updatedData.N_Procedimento) {
      alert('Os campos Numero do Livro, DATA de APREENSAO e Número do Procedimento são obrigatórios.');
      return;
    }

    // Aqui você pode enviar os dados atualizados para o servidor, se necessário

    console.log('Dados enviados para atualização:', updatedData);
    console.log ("registro a atualizar numord = " + updatedData.numord)
    // Enviar os dados atualizados para o servidor usando Axios
    try {
      // Make a PUT request to update the record
      axios.put(`http://ronnie.dev.br:3003/registros/${updatedData.numord}`, updatedData);
      
      // Optionally handle success or inform the user
      alert('Registro atualizado com sucesso.');
      // window.location.reload();
      fetchRegistros(); // Atualizar apenas os registros após o upload, sem recarregar a página

      // Close the modal
      closeModal();
      
      // Refetch the updated records
      fetchRegistros();
    } catch (error) {
      console.error('Erro ao atualizar registro:', error);
      alert('Erro ao atualizar registro.');
    }
    closeModal();
  };
};


const closeModal = () => {
  setShowModal(false);
  setCurrentRecord(null);
};

const fetchRegistros = async () => {
  try {
      const response = await axios.get('http://ronnie.dev.br:3003/registros');

      // Ordenar os registros por n_livro em ordem crescente
      const sortedData = response.data.sort((a, b) => {
          return a.n_livro - b.n_livro; // Assumindo que n_livro é um número
      });

      setRegistros(sortedData);
      setRegistrosFiltrados(sortedData);
      console.log('Registros recebidos:', sortedData);
  } catch (error) {
      console.error('Erro ao buscar registros:', error);
  }
};


useEffect (()=>{
    fetchRegistros();
},[])

// const handleFileChange = (e) => {
//   setFormData({ ...formData, imagens: Array.from(e.target.files) });
// };


/* 
Explicação da função handleFileChange com verificação se há mais de 4 imagens selecionadas
Verificação do Número de Imagens: A função conta quantas imagens já estão no estado formData.imagens e verifica se somando as novas imagens excede 4.

Mensagem de Erro: Se o número total de imagens exceder 4, um alert é exibido e a função é encerrada sem atualizar o estado.

Atualização do Estado: Se a seleção for válida, o estado é atualizado com as novas imagens.
*/
const handleFileChange = (e) => {
  const files = Array.from(e.target.files);
  
  // Verifica se já existem imagens no estado
  const currentImagesCount = formData.imagens ? formData.imagens.length : 0;
  // Verifica se a soma excede 4
  if (currentImagesCount + files.length > 4) {
    alert('Você não pode selecionar mais de 4 imagens.');
    return;
  }
  // Atualiza o estado apenas se a seleção for válida
  setFormData({ ...formData, imagens: [...(formData.imagens || []), ...files] });
};


const handleSubmit = async (e) => {
  e.preventDefault();
  // Verificação de campos obrigatórios
  const { n_livro, data_apreensao, n_procedimento } = formData;
  if (!n_livro || !data_apreensao || !n_procedimento) {
    alert('Os campos Numero do Livro, DATA de APREENSAO e Número do Procedimento são obrigatórios.');
    return;
  }
  const formDataToSend = new FormData();
  Object.entries(formData).forEach(([key, value]) => {
    if (key === 'imagens') {
      formData.imagens.forEach((imagem) => {
        formDataToSend.append('imagens', imagem);
      });
    } else {
      formDataToSend.append(key, value === '' ? null : value); // Enviar null se estiver vazio
    }
  });
  try {
    await axios.post('http://ronnie.dev.br:3003/upload', formDataToSend, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    alert('Upload realizado com sucesso.');
    // window.location.reload();
    fetchRegistros(); // Atualizar apenas os registros após o upload, sem recarregar a página
  } catch (error) {
    console.error('Erro ao fazer upload:', error);
    alert('Erro ao fazer upload.');
  }
}

const handleChange = (e) => {

  const { name, value } = e.target;

  setFormData({ ...formData, [name]: value });

  if (name === 'n_livro' || name === 'data_apreensao' || name === 'n_procedimento' || name === 'ip_boa' ||
      name === 'n_autos' || name === 'tipo' || name === 'marca_modelo' || name === 'cor' || name === 'placa' || 
      name === 'chassi' || name === 'n_laudo' || name === 'obs' || name === 'data_entrega'){
        //capturar o(s) campo(s) modificados no onChange
        const nLivro = name === "n_livro" ? value : formData.n_livro;
        const nProcedimento = name === "n_procedimento" ? value : formData.n_procedimento;
        const placa = name === "placa" ? value : formData.placa;
        const chassi = name === "chassi" ? value : formData.chassi;
        const nLaudo = name === "n_laudo" ? value : formData.n_laudo;
        const obs = name === "obs" ? value : formData.obs;
        const ipBoa = name === "ip_boa" ? value : formData.ip_boa;
        const nAutos = name === "n_autos" ? value : formData.n_autos;
        const tipo = name === "tipo" ? value : formData.tipo;
        const marcaModelo = name === "marca_modelo" ? value : formData.marca_modelo;
        const cor = name === "cor" ? value : formData.cor;
        const dataApreensao = name === "data_apreensao" ? value : formData.data_apreensao || '';
        const dataEntrega = name === "data_entrega" ? value : formData.data_entrega || '';
          
        filterRegistros(
          nLivro, nProcedimento, placa, chassi, nLaudo, obs,
          ipBoa, nAutos, tipo, marcaModelo, cor, dataApreensao, dataEntrega
        );
    }
}

const filterRegistros = (nLivro, nProcedimento, placa, chassi, nLaudo, obs, ipBoa, nAutos, tipo, marcaModelo, cor, dataApreensao, dataEntrega) => {
  const filtered = registros.filter(registro => {
    //Converte os valores do registro para string e minúsculas: Isso é feito para garantir que a comparação seja feita de maneira consistente, independentemente do formato original (maiúsculas/minúsculas, etc.):
    const livro = registro.n_livro ? String(registro.n_livro).toLowerCase() : '';
    const procedimento = registro.n_procedimento ? String(registro.n_procedimento).toLowerCase() : '';
    const registroPlaca = registro.placa ? String(registro.placa).toLowerCase() : '';
    const registroChassi = registro.chassi ? String(registro.chassi).toLowerCase() : '';
    const laudo = registro.n_laudo ? String(registro.n_laudo).toLowerCase() : '';
    const observacao = registro.obs ? String(registro.obs).toLowerCase() : '';
    const ipBoaRegistro = registro.ip_boa ? String(registro.ip_boa).toLowerCase() : '';
    const autosRegistro = registro.n_autos ? String(registro.n_autos).toLowerCase() : '';
    const tipoRegistro = registro.tipo ? String(registro.tipo).toLowerCase() : '';
    const marcaModeloRegistro = registro.marca_modelo ? String(registro.marca_modelo).toLowerCase() : '';
    const corRegistro = registro.cor ? String(registro.cor).toLowerCase() : '';
    const apreensao = registro.data_apreensao ? String(registro.data_apreensao) : '';
    const entrega = registro.data_entrega ? String(registro.data_entrega) : '';

    let dataEntregaMatches = true;
    if (dataEntrega === 'comData') {
      dataEntregaMatches = entrega !== '';
    } else if (dataEntrega === 'semData') {
      dataEntregaMatches = entrega === '';
    } else {
      dataEntregaMatches = entrega.includes(dataEntrega);
    }

    return (
      livro.includes(nLivro.toLowerCase()) &&
      procedimento.includes(nProcedimento.toLowerCase()) &&
      registroPlaca.includes(placa.toLowerCase()) &&
      registroChassi.includes(chassi.toLowerCase()) &&
      laudo.includes(nLaudo.toLowerCase()) &&
      observacao.includes(obs.toLowerCase()) &&
      ipBoaRegistro.includes(ipBoa.toLowerCase()) &&
      autosRegistro.includes(nAutos.toLowerCase()) &&
      tipoRegistro.includes(tipo.toLowerCase()) &&
      marcaModeloRegistro.includes(marcaModelo.toLowerCase()) &&
      corRegistro.includes(cor.toLowerCase()) &&
      apreensao.includes(dataApreensao) &&
      dataEntregaMatches
    )
  });
  setRegistrosFiltrados(filtered);
}

const handleDelete = async (id) => {
  try {
    await axios.delete(`http://ronnie.dev.br:3003/registros/${id}`);
    alert('Registro deletado com sucesso.');
    fetchRegistros();
  } catch (error) {
    console.error('Erro ao deletar registro:', error);
    alert('Erro ao deletar registro.');
  }
}

const formatarData = (dataISO) => {
  if (!dataISO) return ''; // Retorna vazio se não houver data
  const data = new Date(dataISO);
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
  const ano = data.getFullYear();
  return `${dia}/${mes}/${ano}`;
};

const openImageInNewTab = (imageUrl) => {
  window.open(imageUrl, '_blank');
};

const handleUpdateClick = (registro) => {
  openModal(registro);
};

const handleDownload = () => {
  // Filtra as colunas que não devem ser incluídas no arquivo ODS
  const dadosParaExportar = registrosFiltrados.map(registro => {
      const { caminho1, caminho2, caminho3, caminho4, numord, ...dadosRestantes } = registro;

      // Formata todas as propriedades que são datas
      Object.keys(dadosRestantes).forEach(key => {
          if (dadosRestantes[key] && typeof dadosRestantes[key] === 'string' && !isNaN(Date.parse(dadosRestantes[key]))) {
              dadosRestantes[key] = formatarData(dadosRestantes[key]);
          }
      });

      // Cria um novo objeto com chaves em caixa alta
      const dadosComChavesEmCaixaAlta = {};
      Object.keys(dadosRestantes).forEach(key => {
          dadosComChavesEmCaixaAlta[key.toUpperCase()] = dadosRestantes[key];
      });

      return dadosComChavesEmCaixaAlta;
  });

  // Cria uma nova planilha
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(dadosParaExportar);

  // Ajusta a largura das colunas automaticamente com base no conteúdo
  const colWidths = dadosParaExportar.reduce((acc, row) => {
      Object.keys(row).forEach((key, i) => {
          const value = row[key];
          const length = value ? value.toString().length : 10;
          acc[i] = Math.max(acc[i] || 20, length);
      });
      return acc;
  }, []);

  ws['!cols'] = colWidths.map(w => ({ wch: w + 2 }));

  // Ajusta a altura das linhas do cabeçalho
  ws['!rows'] = [{ hpx: 40 }];

  // Adiciona a planilha ao workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Registros');

  // Define as opções de arquivo
  const wbout = XLSX.write(wb, { bookType: 'ods', type: 'binary' });

  // Cria um Blob e um link para download
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'registros.ods';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
};


return (
<div className="container-fluid">
    <h4>Veículos Apreendidos - 18ª SDP</h4>
    <div className="row">
      <form className="col s12" onSubmit={handleSubmit} >
        <div className='row'>
        {Object.entries(formData).map(([key, value]) => (
          key === 'imagens' ? (
            <label key={key} style={{ display: 'block', marginBottom: '5px', padding: '5px'}}> Imagens:
            <br />
            <input type="file" name={key} multiple  onChange={handleFileChange} />
          </label>
          ) : (
            <div key={key} className="input-field col s2">
              <input id={key} type="text" name={key} className="validate" value={value} onChange={handleChange}/>
              <label htmlFor="login">{key.toUpperCase()}</label>
            </div>
          )))}
          <button className="btn waves-effect waves-light blue" type="submit" name="action">Inserir
            <i className="material-icons right">send</i>
          </button>
        </div>
      </form>
      {/* div para tratar da linha onde temos o botão baixar registros a esquerda, o título Apreensões 18ª SDP no meio e o select filtrar por Data de Entrega a direita */}
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        {/* Ícone e Baixar Registros à esquerda */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaDownload className='BotaoDownload' style={{ marginRight: '8px' }} onClick={handleDownload}/>
          <span>Baixar registros</span>
        </div>
        {/* Texto centralizado */}
        <h5 style={{ margin: 0, textAlign: 'center'}}>Apreensões 18ª SDP</h5>
        <div style={{display:'flex', flexDirection:'column'}}> 

          <select
            id='Seletor'
            name="data_entrega"
            value={formData.data_entrega} // Atribui o valor do estado ao select
            onChange={handleChange} // Atualiza o estado quando o valor é alterado
            className="browser-default"
          >
            <option value="" disabled>filtrar por Data de Entrega</option>
            <option value="">Todos</option>
            <option value="comData">Com Data de Entrega</option>
            <option value="semData">Sem Data de Entrega</option>
          </select>
        </div>
      </div>

      <div className="table-container">  
        <table>
        <thead style={{ backgroundColor: 'lightgreen', position: 'sticky', top: '-10px', zIndex: 1 }}>
            <tr>
                <th>Número do livro</th>
                <th>Data da Apreensão</th>
                <th>Número do Procedimento</th>
                <th>tipo</th>
                <th>Placa</th>
                <th>Cor</th>
                <th>Data de Entrega</th>
                <th>IMG 1</th>
                <th>IMG 2</th>
                <th>IMG 3</th>
                <th>IMG 4</th>
                <th>Visualizar</th>
                <th>Remover</th>
            </tr>
          </thead>

          <tbody>
            {registrosFiltrados.map((registro) => (
              <tr key={registro.numord} style={{backgroundColor: registro.data_entrega !== null ? 'lightgreen' : '' }}>
                <td>{registro.n_livro}</td>
                <td>{formatarData(registro.data_apreensao)}</td>
                <td>{registro.n_procedimento}</td>
                <td>{registro.tipo}</td>
                <td>{registro.placa}</td>
                <td>{registro.cor}</td>
                <td>{formatarData(registro.data_entrega)}</td>
                <td className='Imagem'>
                  {registro.caminho1 ? (
                      <img
                          src={`http://ronnie.dev.br:3003/${registro.caminho1}`}
                          alt={`Imagem 1`}
                          style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                          onClick={() => openImageInNewTab(`http://ronnie.dev.br:3003/${registro.caminho1}`)}
                      />
                  ) : null}
                </td>
                <td className='Imagem' >
                  {registro.caminho2 ? (
                      <img
                          src={`http://ronnie.dev.br:3003/${registro.caminho2}`}
                          alt={`Imagem 2`}
                          style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                          onClick={() => openImageInNewTab(`http://ronnie.dev.br:3003/${registro.caminho2}`)}
                      />
                  ) : null}
                </td>
                <td className='Imagem' >
                  {registro.caminho3 ? (
                      <img
                          src={`http://ronnie.dev.br:3003/${registro.caminho3}`}
                          alt={`Imagem 3`}
                          style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                          onClick={() => openImageInNewTab(`http://ronnie.dev.br:3003/${registro.caminho3}`)}
                      />
                  ) : null}
                </td>
                <td className='Imagem' >
                  {registro.caminho4 ? (
                      <img
                          src={`http://ronnie.dev.br:3003/${registro.caminho4}`}
                          alt={`Imagem 4`}
                          style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                          onClick={() => openImageInNewTab(`http://ronnie.dev.br:3003/${registro.caminho4}`)}
                      />
                  ) : null}
                </td>

                <td className='Atualizar' onClick={() => handleUpdateClick(registro)}><FaMagnifyingGlass /></td>
                <td className='Deletar' onClick={() => handleDelete(registro.numord)}><FaTrashCan /></td>
              </tr>
            ))
            }
          </tbody>
        </table>
      </div>        
    </div>
  </div>

  )
}

export default VeiculosApreendidos