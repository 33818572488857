import React from 'react';

const Register = () => {
  return (
    <div style={{ marginTop: '100px', textAlign: 'center' }}>
      <h2>Registro no Sistema Pesquisa de Apreensões da PCPR</h2>
      <p>
        Para se registrar, entre em contato com o desenvolvedor:
      </p>
      <p>
        <strong>Email:</strong> esc.rkindrech@pc.pr.gov.br
      </p>
      <p>
        <strong>Telefone:</strong> 41 - 99640-8741
      </p>
      <p>
        Agradecemos seu interesse!
      </p>
    </div>
  );
};

export default Register;
